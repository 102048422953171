import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import AddRoomGroup from "components/ModelAdd/AddRoomGroup";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import ModelData from "components/ModelAdd/ModelData";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
const ListProperties = () => {
  const [modalVisible, setModalVisible] = useState(true);
  const [AddmodalVisible, setAddModalVisible] = useState(false);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const addProperty = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    addProperty();
  }, []);

  useEffect(() => {
    const filteredData = properties.filter((property) =>
      property.property_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProperties(filteredData);
  }, [searchTerm, properties]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setModalVisible(true);
  };

  const handleProperty = (propertyId) => {
    navigate(`/properties/list-properties/${propertyId}/room`);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedProperty(null);
  };
  const shouldShowModal = location.pathname !== "/properties/list-properties";
  const columns = [
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Property name
        </MDTypography>
      ),
      accessor: "propertyname",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          City
        </MDTypography>
      ),
      accessor: "city",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Zip code
        </MDTypography>
      ),
      accessor: "zip",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Ward
        </MDTypography>
      ),
      accessor: "ward",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Location
        </MDTypography>
      ),
      accessor: "location",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Street
        </MDTypography>
      ),
      accessor: "street",
    },
    ...(shouldShowModal
      ? [
          {
            Header: () => (
              <MDTypography
                variant="h6"
                style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
              >
                Actions
              </MDTypography>
            ),
            accessor: "actions",
          },
        ]
      : []),
  ];
  const rows = filteredProperties
    .sort((a, b) => a.property_name.localeCompare(b.property_name))
    .map((property) => ({
      propertyname: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px", cursor: "pointer", color: "#1e90FF" }}
          onClick={() => handleProperty(property.property_id)}
        >
          {property.property_name || ""}
        </MDTypography>
      ),
      city: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.city || ""}
        </MDTypography>
      ),
      zip: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.zip || ""}
        </MDTypography>
      ),
      ward: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.ward || ""}
        </MDTypography>
      ),
      location: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.location || ""}
        </MDTypography>
      ),
      street: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {property.street || ""}
        </MDTypography>
      ),
      actions: shouldShowModal ? (
        <div>
          <IconButton onClick={() => handleEdit(property)} sx={{ color: "#1e90ff" }}>
            <EditIcon />
          </IconButton>
        </div>
      ) : null,
    }));

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {loading ? (
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  align="center"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                </MDTypography>
              ) : (
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    sx={{ backgroundColor: "#1e90ff" }}
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h6" color="white">
                      Properties Tables
                    </MDTypography>
                    <MDBox display="flex" justifyContent="flex-end" alignItems="center">
                     
                     {shouldShowModal &&(

                    <MDButton
                        style={{
                          color: "black",
                          border: "1px solid white",
                          background: "#white",
                          marginRight: "16px",
                          padding: "3px 10px",
                        }}

                        onClick={() =>     setModalVisible(true)}
                      >
                        Add Property
                      </MDButton>
                       )} 
                      <TextField
                        placeholder="Search property"
                        size="small"
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            border: "none",
                            padding: "3px 10px",
                          },
                          "& .MuiInputLabel-root": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: "8px",
                          },
                        }}
                        inputProps={{ "aria-label": "Search" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearch}
                        value={searchTerm}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    {filteredProperties.length > 0 ? (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    ) : (
                      <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                        No properties found.
                      </MDTypography>
                    )}
                  </MDBox>
                </Card>
              )}
            </Grid>
          </Grid>
        </MDBox>
        {shouldShowModal && (
          <ModelData
            isVisible={modalVisible}
            addProperty={addProperty}
            close={closeModal}
            selectedProperty={selectedProperty}
          />
        )}
        <AddRoomGroup
          isVisible={AddmodalVisible}
          addProperty={addProperty}
          Close={() => {
            setAddModalVisible(false);
          }}
        />
      </DashboardLayout>
    </>
  );
};

export default ListProperties;
