import { Button, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import AddRoom from "components/ModelAdd/AddRoom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import React, { useState, useEffect } from "react";
import routes from "routes";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReservationModel from "components/ModelAdd/ReservationModel";
import ExpensesModel from "components/ModelAdd/ExpensesModel";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CleaningModel from "components/Cleaning/CleaningModel";
const RoomDetails = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [rooms, setRooms] = useState([]);
  const { id } = useParams();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [Isreservation, setIsreservation] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [IsExpensesModel, setIsExpensesModel] = useState(false);
  const [cleaningData,setCleaningData]=useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchRoomData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getRoomData`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setRooms(response.data.data);
      } else {
        console.error("Failed to fetch room data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching room data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRoomData();
  }, []);

  const handleReservation = (roomId) => {
    setIsreservation(true);
    setSelectedRoomId(roomId);
  };
  const handleExpensess = (roomId) => {
    setIsExpensesModel(true);
    setCleaningData(roomId)
  };
  // const handleroom = (roomId) => {
  //   navigate(`/properties/reserved-room/${id}/${roomId}`);
  // };
  const filteredRooms = rooms.filter(
    (room) => room && room.property_id && room.property_id.toString() === id
  );
  const hasRooms = filteredRooms.length > 0;

  const handleEdit = (room) => {
    setSelectedRoom(room);
    setModalVisible(true);
  };
  const handleClose = () => {
    setIsreservation(false);
    setSelectedRoomId(null);
  };
  const handleDelete = async (roomId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this room?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    });
    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_PATH}/deleteRoom/${roomId}`
        );

        if (response.data.success) {
          Swal.fire({
            title: "Deleted!",
            text: "room deleted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          fetchRoomData();
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to delete room!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "An error occurred while deleting the property!",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const columns = [
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Room no
        </MDTypography>
      ),
      accessor: "room_no",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Room type
        </MDTypography>
      ),
      accessor: "room_type",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Bed
        </MDTypography>
      ),
      accessor: "bed",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Room size (sqm)
        </MDTypography>
      ),
      accessor: "room_size_sqm",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Rent history
        </MDTypography>
      ),
      accessor: "rent_history",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Short term (daily rent)
        </MDTypography>
      ),
      accessor: "sort_term_daily_rent",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Pdf (File)
        </MDTypography>
      ),
      accessor: "files", 
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Schedule cleaning
        </MDTypography>
      ),
      accessor: "cleaning",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Reservation
        </MDTypography>
      ),
      accessor: "reservation",
    },
    {
      Header: () => (
        <MDTypography variant="h6" style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}>
          Actions
        </MDTypography>
      ),
      accessor: "actions",
    },
  ];

  const rows = filteredRooms.map((room) => ({
    room_no: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.room_no || ""}
      </MDTypography>
    ),
    room_type: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.room_type || ""}
      </MDTypography>
    ),
    bed: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.bed || ""}
      </MDTypography>
    ),
    room_size_sqm: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.room_size_sqm || ""}
      </MDTypography>
    ),
    rent_history: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.rent_history || ""}
      </MDTypography>
    ),
    sort_term_daily_rent: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {room.sort_term_daily_rent || ""}
      </MDTypography>
    ),
    files: room.pdf_file ? (
      <a href={room.pdf_file|| ""} target="_blank" rel="noopener noreferrer">
        View Pdf
      </a>
    ) : (
      ""
    ),
    cleaning: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        <div>
          <MDButton
            style={{
              color: "white",
              border: "1px solid white",
              background: "#1e90ff",
            }}
            onClick={() => handleExpensess(room)}
          >
        Schedule Cleaning
          </MDButton>
        </div>
      </MDTypography>
    ),
    reservation: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        <div>
          <MDButton
            style={{
              color: "white",
              border: "1px solid white",
              background: "#1e90ff",
            }}
            onClick={() => handleReservation(room)}
          >
          Add  reservation
          </MDButton>
        </div>
      </MDTypography>
    ),
    actions: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        <div>
          <IconButton onClick={() => handleEdit(room)} sx={{ color: "#1e90ff" }}>
            <EditIcon />
          </IconButton>
          {/* <IconButton onClick={() => handleDelete(room.room_id)} color="primary">
            <DeleteIcon />
          </IconButton> */}
        </div>
      </MDTypography>
    ),
  }));
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />

      
     {loading ? (<MDBox
          pt={3}
          pb={3}
        >
    
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            align="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
          </MDTypography>
        
        </MDBox>
        ) : (
          <>
            {!hasRooms && ( 
             <MDBox
          pt={6}
          pb={3}
          mt={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height:"80vh"
          }}
        >
      
            <MDButton
              style={{
                color: "white",
                border: "1px solid white",
                background: "#0d6efd",
                marginBottom: "16px",
              }}
           
              onClick={() => setModalVisible(true)}
            >
              Add Room
            </MDButton>
            <MDBox display="flex" justifyContent="center"    mb={4}>
                <MDTypography variant="h6" color="text">
              <>No room data available. Please add a room.</>
                </MDTypography>
              </MDBox>
            <Button
                          style={{
                            color: "white",
                            border: "1px solid #0d6efd",
                            background: "#0d6efd",
                            marginRight: "8px",
                          }}
                          onClick={handleBack}
                        >
                          <ArrowBackIcon style={{ marginRight: "8px" }} />
                          Back
                        </Button>
        </MDBox>
 )}
          <MDBox pt={3}>
            {hasRooms && (
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      sx={{ backgroundColor: "#1e90ff" }}
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="white" style={{ float: "left" }}>
                        Room Details
                      </MDTypography>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <Button
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                            marginRight: "8px",
                          }}
                          onClick={() => setModalVisible(true)}
                        >
                          Add Room
                        </Button>
                        <Button
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                            marginRight: "8px",
                          }}
                          onClick={handleBack}
                        >
                          <ArrowBackIcon style={{ marginRight: "8px" }} />
                          Back
                        </Button>
                      </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            )}
          </MDBox>
          </>
        )}
        <AddRoom
          isVisible={modalVisible}
          Close={() => {
            setModalVisible(false);
            setSelectedRoom(null);
          }}
          onAddRoom={fetchRoomData}
          selectedRoom={selectedRoom}
        />

        <ReservationModel
          isVisible={Isreservation}
          Close={handleClose}
          propertyId={id}
          roomId={selectedRoomId}
        />
        <CleaningModel
          isVisible={IsExpensesModel}
          Close={() => {
            setIsExpensesModel(false);
          }}
          roomId={cleaningData}
        />
      </DashboardLayout>
    </>
  );
};

export default RoomDetails;
