import * as React from "react";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, Autocomplete, TextField, FormControl, Modal } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";
const style = {
  position: "absolute",
  top: { xs: 340, sm: 340, md: 340, xl: 400 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  pt: 2,
  pb: 3,
};

const CleaningModel = ({ isVisible, Close,fetchCleaningData,roomId }) => {
  const [IsSelectRoom,setSelectRoom]=useState(null);
  const [IsSelectProperty, setSelectProperty]=useState(null);
 
  const [availableCleaners, setAvailableCleaners] = useState([]);
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);

const { id } = useParams(); 
const propertyIdFromParams = Number(id);
const PropertiesId = roomId?.property_id || IsSelectProperty;
const RoomIds = roomId?.room_id || IsSelectRoom;
useEffect(() => {
 
  const ReservationData = availableCleaners.find(
    cleaner => cleaner.property_id === propertyIdFromParams
  );
  if (ReservationData) {
    setSelectRoom(ReservationData.room_id);
    setSelectProperty(ReservationData.property_id);
  } 
}, [id, availableCleaners]); 
const validationSchema = Yup.object({
  property: propertyIdFromParams
    ? Yup.object().nullable() 
    : Yup.object()
        .shape({
          property_id: Yup.mixed().required("Property is required"),
          property_name: Yup.mixed().required("Property name is required"),
        })
        .nullable()
        .required("Property is required"),

  room: propertyIdFromParams
    ? Yup.object().nullable()
    : Yup.object()
        .shape({
          room_id: Yup.string().required("Room is required"),
          room_no: Yup.string().required("Room number is required"),
        })
        .nullable()
        .required("Room is required"),
});
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      todo:"",
      maintenance:"",
      cleaning_date:"",
      property: PropertiesId,
      room: RoomIds,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = {
          ...values,
          room_id: RoomIds,
          property_id:PropertiesId,
        };
        let response;
          response = await axios.post(
            `${process.env.REACT_APP_API_PATH}/cleaningdata`,
            formData
          );

          closeModal();
          fetchCleaningData(formData);
        if (!reservationUpdateResponse?.data?.success) {
          throw new Error("Failed to update reservation data.");
        }
        if (response?.data?.success) {
          toast.success("Cleaning data submitted successfully!");
        } else {
          toast.error("Failed to submit cleaning data.");
        }
        formik.resetForm();
      } catch (error) {
        console.error("Failed to add/update cleaning data. Please try again.", error);
      }
    },
    
  });

  const filteredRooms = rooms.filter((room) => room.property_id === IsSelectProperty);
  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );

 

  const addAvailableCleaners = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getcleaningdata`);
      if (response.data.success) {
        setAvailableCleaners(response.data.data); 
      }
    } catch (error) {
      console.error("Failed to fetch available cleaners!");
    }
  };
  const addProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };
  const fetchRoomData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getRoomData`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setRooms(response.data.data);
      } else {
        console.error("Failed to fetch room data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching room data:", error);
    }
  };
  useEffect(() => {
    fetchRoomData();
    addProperty();
    addAvailableCleaners()
  }, []);

  const closeModal = () => {
    formik.resetForm();
    Close();
  };
  
  return (
    <>
    <Modal
    open={isVisible}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
   
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            ...style,
            width: {
              xs: "90%",
              sm: "80%",
              md: "500px",
              lg: "60%",
              xl: "600px",
            },
          }}
        >
          <MDBox
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            sx={{ backgroundColor: "#1e90ff" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Assign Cleaner 
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            
            <Grid container spacing={2}>
        {!propertyIdFromParams && (
<>

   <Grid item xs={6}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={properties}
                            getOptionLabel={(option) => option.property_name}
                            onChange={(event, value) => {
                              formik.setFieldValue("property", value);
                              setSelectProperty(value?.property_id);
                            }}
                            value={formik.values.property || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Property"
                                error={formik.touched.property && Boolean(formik.errors.property)}
                                helperText={formik.touched.property && formik.errors.property}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>

                    <Grid item xs={6}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={filteredRooms}
                            getOptionLabel={(option) => option.room_no}
                            onChange={(event, value) => {
                              formik.setFieldValue("room", value);
                              setSelectRoom(value?.room_id);
                            }}
                            value={formik.values.room || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Room"
                                error={formik.touched.room && Boolean(formik.errors.room)}
                                helperText={formik.touched.room && formik.errors.room}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>
</>
        )}   
              {[
                { label: "Name", name: "name" ,type:"select"},
                { label: "Email", name: "email", type: "email" },
                { label: "Cleaning Date", name: "cleaning_date", type:"date" },
                { label: "Maintenance", name: "maintenance" ,type: "textarea" },
                { label: "Todo List", name: "todo",type: "textarea" },
                
              ].map(({ label, name, options, type = "text" }) => (
                <Grid item xs={6} key={name}>
                  <MDBox mb={2}>
                    {options ? (
                      <Autocomplete
                        disablePortal
                        options={options}
                        value={formik.values[name]}
                        onChange={(event, value) => {
                          formik.setFieldValue(name, value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={label}
                            error={formik.touched[name] && Boolean(formik.errors[name])}
                          />
                        )}
                      />
                    ) : type === "textarea" ? (
                      <TextField
                        label={label}
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                      />
                    ) : (
                      <MDInput
                        type={type}
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          placeholder: type === "date" || type === "time" ? "" : undefined,
                        }}
                      />
                    )}
                    <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched[name] && formik.errors[name] && (
                        <div>{formik.errors[name]}</div>
                      )}
                    </div>
                  </MDBox>

                </Grid>
              ))}
                        
            </Grid>
            <MDBox mt={4} mb={1}>
              <MDButton
                sx={{ backgroundColor: "#0d6efd" }}
                color="info"
                fullWidth
                type="submit"
                disabled={!isFormFilled }
              >
             Submit
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <Button sx={{ color: "#0d6efd" }} fullWidth onClick={closeModal}>
                Close
              </Button>
              </MDBox>
            <MDTypography color="error" variant="h6">
 
</MDTypography>

          </MDBox>
        </Card>
      </form>
      </Modal>
      <ToastContainer limit={1} />
   
      </>
  );
};

export default CleaningModel;