import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, Autocomplete, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: { xs: 400, sm: 400, md: 400, xl: 400 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const roomTypes = ["2BHK", "3BHK"];
const bedOptions = ["1", "2", "3", "4"];
const AddRoomGroup = (props) => {
  const { isVisible, Close } = props;
  const formik = useFormik({
    initialValues: {
      roomNo: "",
      fromNo: "",
      type: "",
      sizeSqm: "",
      sizeJou: "",
      beds: "",
      rentHistory: "",
      shortTermDailyRent: "",
      utilitiesHistory: "",
    },
    onSubmit: (values) => {
      formik.resetForm();
    },
    validate: (values) => {
      const errors = {};
      if (!values.roomNo) errors.roomNo = "Room Number is required";
      if (!values.fromNo) errors.fromNo = "Room Number is required";
      if (!values.type) errors.type = "Room Type is required";
      if (!values.sizeSqm) errors.sizeSqm = "Size (sqm) is required";
      if (!values.sizeJou) errors.sizeJou = "Size (jou) is required";
      if (!values.beds) errors.beds = "Number of Beds is required";
      return errors;
    },
  });

  const closeModal = () => {
    formik.resetForm();
    Close();
  };

  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "600px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Add Room Group
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {[
                  { label: "To Room Number", name: "roomNo", type: "text" },
                  { label: "From Room Number", name: "roomNo", type: "text" },
                  { label: "Type", name: "type", options: roomTypes },
                  { label: "Bed", name: "beds", options: bedOptions },
                  { label: "Size (sqm)", name: "sizeSqm", type: "text" },
                  { label: "Size (jou)", name: "sizeJou", type: "text" },
                  { label: "Rent History", name: "rentHistory", type: "text" },
                  { label: "Short Term Daily Rent", name: "shortTermDailyRent", type: "text" },
                  { label: "Utilities History", name: "utilitiesHistory", type: "text" },
                ].map(({ label, name, options, type = "text" }) => (
                  <Grid item xs={6} key={name}>
                    <MDBox mb={2}>
                      {options ? (
                        <Autocomplete
                          disablePortal
                          options={options}
                          onChange={(event, value) => {
                            formik.setFieldValue(name, value);
                          }}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={label}
                              error={formik.touched[name] && Boolean(formik.errors[name])}
                            />
                          )}
                        />
                      ) : (
                        <MDInput
                          type={type}
                          label={label}
                          fullWidth
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      )}
                      <div style={{ color: "red", fontSize: "17px" }}>
                        {formik.touched[name] && formik.errors[name] ? (
                          <div>{formik.errors[name]}</div>
                        ) : null}
                      </div>
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
              <MDBox mt={4} mb={1}>
                <MDButton sx={{ backgroundColor: "#0d6efd" }} color="info" fullWidth type="submit">
                  Submit
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <Button sx={{ color: "#0d6efd" }} fullWidth onClick={closeModal}>
                  Close
                </Button>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </Modal>
    </>
  );
};

export default AddRoomGroup;
