import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import "Styles.css";
import 'react-toastify/dist/ReactToastify.css';
const Basic = lazy(() => import("layouts/authentication/sign-in"));
const Forget = lazy(() => import("layouts/authentication/sign-in/Forget"));
const ResetPassword = lazy(() => import("layouts/authentication/sign-in/ResetPassword"));

const MonthlyData = lazy(() => import("components/MonthlyReport/index"));
const RoomReservationData = lazy(() => import("components/properties/RoomReservationData"));
const Changepassword = lazy(() => import("layouts/authentication/sign-in/Changepassword"));
const ExpensessData = lazy(() => import("./components/Expenses/index"));
const Overview = lazy(() => import("components/Overview/index"));
const Cleaning = lazy(() => import("components/Cleaning/index"));
const Profile = lazy(() => import("components/userProfile/index"));
import ListProperties from "components/properties/ListProperties";
import PublicRoutes from "PublicRoutes";
import PrivateRoutes from "PrivateRoutes";
import RoomDetails from "components/properties/RoomDetails";
import CircularProgress from '@mui/material/CircularProgress'
import CleaningModel from "components/Cleaning/CleaningModel";
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={
  <div 
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", 
      width: "100%",    
    }}
  >
      <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
  </div>
}>


        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Basic />} />
            <Route path="/authentication/sign-in/forget" element={<Forget />} />
            <Route path="/authentication/sign-in/reset/:token" element={<ResetPassword />} />
          </Route>
            <Route path="/cleaning" element={<Cleaning/>} />
          <Route element={<PrivateRoutes />}>
            <Route path="/properties/add-properties" element={<ListProperties />} />
            <Route path="/properties/list-properties" element={<ListProperties />} />
            <Route path="/properties/list-properties/:id/room" element={<RoomDetails />} />
            <Route path="/change/password" element={<Changepassword/>}/>
            {/* <Route path="/monthly" element={<MonthlyData/>} /> */}
            <Route path="/dashboard" element={<Overview/>} />
            <Route path="/expense" element={<ExpensessData/>} />
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/room-reservation" element={<RoomReservationData/>} />
            <Route path="/cleaner" element={<CleaningModel/>} />
          </Route>
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
