import { Navigate, Outlet } from "react-router";
import Cookies from "js-cookie";
const PublicRoutes = () => {
  const token = localStorage.getItem("token"); 
  // const token = Cookies.get("token");
  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

export default PublicRoutes;

