import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Button, Card, Grid, TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as pdfjsLib from "pdfjs-dist";
import Tesseract from "tesseract.js";
import { useParams } from "react-router-dom";
dayjs.extend(timezone);
if (typeof pdfjsLib.GlobalWorkerOptions !== "undefined") {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;
}
const style = {
  position: "absolute",
  top: { xs: 450, sm: 450, md: 450, xl: 450 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const bookingSources = [
  "Airbnb",
  "Roomorama",
  "Holiday Lettings",
  "HomeAway",
  "TravelMob",
  "Wimdu",
  "Direct",
];

const paymentSources = [
  "wise",
  "Revolut",
  "int bank transfer",
  "local bank transfer",
  "credit card",
];
const currencySources = ["JPY"];
const ReservationModel = ({ isVisible, Close, selectedProperty, addReservationData, roomId }) => {
  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [IsSelectProperty, setSelectProperty] = useState(null);

  const [IsSelectRoom, setSelectRoom] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const params = useParams();
  const PropertiesId = roomId?.property_id || IsSelectProperty;
  const RoomIds = roomId?.room_id || IsSelectRoom;

  const shouldShowModal = location.pathname !== `/properties/list-properties/${params.id}/room`;
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    arrived_date: Yup.date().required("Arrival date is required"),
    departure_date: Yup.date()
      .min(Yup.ref("arrived_date"), "Departure must be after arrival")
      .required("Departure date is required"),
  });
  const validationDataSchema = Yup.object({
    property: Yup.object()
      .shape({
        property_id: Yup.mixed().required("Property is required"),
        property_name: Yup.mixed().required("Property name is required"),
      })
      .nullable()
      .required("Property is required"),
    room: Yup.object()
      .shape({
        room_id: Yup.string().required("Room is required"),
        room_no: Yup.string().required("Room number is required"),
      })
      .nullable()
      .required("Room is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      arrived_date: "",
      departure_date: "",
      check_in_time: "",
      check_out_time: "",
      guest: "",
      notes: "",
      booking_source: "",
      currency: "",
      amount: "",
      rent_amount: "",
      deposit_amount: "",
      payment_method: "",
      total_stay: "",
      payment_receipt: "",
      property:PropertiesId,
      room:RoomIds,
    },

    validationSchema: shouldShowModal ? validationSchema.concat(validationDataSchema) : validationSchema,
    onSubmit: async (values) => {
      try {
        let response;

        if (selectedProperty) {
          response = await axios.put(
            `${process.env.REACT_APP_API_PATH}/property/${PropertiesId}/room/${RoomIds}/updateRoomReservation/${selectedProperty.reservation_id}`,
            values
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_API_PATH}/property/${PropertiesId}/room/${RoomIds}/reservation`,
            values
          );
        }

        if (response && response.status === 200) {
          toast.success(
            selectedProperty
              ? "Room Reservation data updated successfully!"
              : "Room reserved successfully!"
          );
          CloseModal();
          addReservationData(values);
          formik.resetForm();
        }
      }catch (error) {
        console.error("Failed to add/update room data. Please try again.", error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } 
      }
      
    },
  });

  useEffect(() => {
    if (selectedProperty) {
      let cleaner = "";
      if (selectedProperty.cleaner) {
        if (typeof selectedProperty.cleaner === "string") {
          try {
            cleaner = JSON.parse(selectedProperty.cleaner);
          } catch (error) {
            console.error("Failed to parse cleaner data:", error);

            cleaner = { name: selectedProperty.cleaner.trim() };
          }
        } else {
          cleaner = selectedProperty.cleaner;
        }
      }

      formik.setValues({
        name: selectedProperty?.name || "",
        email: selectedProperty?.email || "",
        phone: selectedProperty?.phone || "",
        arrived_date: dayjs(selectedProperty.arrived_date).format("YYYY-MM-DD") || "",
        departure_date: dayjs(selectedProperty.departure_date).format("YYYY-MM-DD") || "",
        check_in_time: selectedProperty.check_in_time || "",
        check_out_time: selectedProperty.check_out_time || "",
        guest: selectedProperty?.guest || "",
        notes: selectedProperty?.notes || "",
        booking_source: selectedProperty?.booking_source || "",
        currency: selectedProperty?.currency || "",
        amount: selectedProperty?.amount || "",
        cleaner: cleaner || "",
        property: {
          property_id: selectedProperty.property_id,
          property_name: selectedProperty.property_name,
        },
        room: {
          room_id: selectedProperty.room_id,
          room_no: selectedProperty.room_no,
        },
        rent_amount: selectedProperty.rent_amount || "",
        deposit_amount: selectedProperty.deposit_amount || "",
        payment_method: selectedProperty.payment_method || "",
        total_stay: selectedProperty.total_stay || "",
      });
      setSelectProperty(selectedProperty.property_id || roomId.property_id);
      setSelectRoom(selectedProperty.room_id || roomId.room_id);
    } else {
      formik.resetForm();
    }
  }, [selectedProperty]);
  const CloseModal = () => {
    formik.resetForm();
    Close();
  };

  const addProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };
  const fetchRoomData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getRoomData`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setRooms(response.data.data);
      } else {
        console.error("Failed to fetch room data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching room data:", error);
    }
  };
  useEffect(() => {
    fetchRoomData();
    addProperty();
  }, []);
  const filteredRooms = rooms.filter((room) => room.property_id === IsSelectProperty);
  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );

  const parseInvoiceAmount = async (file) => {
    try {
      const fileType = file.type.toLowerCase();

      if (fileType.includes("pdf")) {
        await parsePdf(file);
      } else if (fileType.includes("image")) {
        await parseImage(file);
      } else {
        console.error("Unsupported file type");
      }
    } catch (error) {
      console.error("Error parsing invoice", error);
    }
  };

  const parsePdf = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target.result);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;

      let totalAmount = "";

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const textItems = textContent.items.map((item) => item.str).join(" ");

        const amountMatch = textItems.match(/Total[\s:]*([\d,.]+)/i);

        if (amountMatch && amountMatch[1]) {
          totalAmount = amountMatch[1];
          break;
        }
      }

      formik.setFieldValue("total_amount", totalAmount);
    };

    fileReader.readAsArrayBuffer(file);
  };

  const parseImage = (file) => {
    Tesseract.recognize(file, "eng", {
      logger: (m) => console.log(m),
    })
      .then(({ data: { text } }) => {
        const amountMatch = text.match(/TOTAL[\s:]*([\d,.]+)/i);
        let totalAmount = amountMatch ? amountMatch[1] : "Amount not found";
        formik.setFieldValue("total_amount", totalAmount);
        formik.setFieldValue("invoice", text);
      })
      .catch((error) => {
        console.error("OCR failed: ", error);
      });
  };

  useEffect(() => {
    if (invoiceFile) {
      parseInvoiceAmount(invoiceFile);
    }
  }, [invoiceFile]);

  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (formik.values.arrived_date && formik.values.departure_date) {
      const arrivedDate = dayjs(formik.values.arrived_date);
      const departureDate = dayjs(formik.values.departure_date);

      if (arrivedDate.isValid() && departureDate.isValid()) {
        const totalStay = departureDate.isAfter(arrivedDate)
          ? departureDate.diff(arrivedDate, "days")
          : 0;

        formik.setFieldValue("total_stay", totalStay);
      }
    }
  }, [formik.values.arrived_date, formik.values.departure_date]);

  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "850px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Room Reservation
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {shouldShowModal && (
                  <>
                    <Grid item xs={4}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={properties}
                            getOptionLabel={(option) => option.property_name}
                            isOptionEqualToValue={(option, value) => {
                              return option.property_id === value?.property_id;
                            }}
                            onChange={(event, value) => {
                              formik.setFieldValue("property", value);
                              setSelectProperty(value?.property_id || PropertiesId);
                             
                            }}
                            value={formik.values.property || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Property"
                                error={formik.touched.property && Boolean(formik.errors.property)}
                                helperText={formik.touched.property && formik.errors.property}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>

                    <Grid item xs={4}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={filteredRooms}
                            getOptionLabel={(option) => option.room_no}
                            isOptionEqualToValue={(option, value) => {
                              return option.room_id === value?.room_id;
                            }}
                            onChange={(event, value) => {
                              formik.setFieldValue("room", value);
                              setSelectRoom(value?.room_id || RoomIds);
                            }}
                            value={formik.values.room || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Room"
                                error={formik.touched.room && Boolean(formik.errors.room)}
                                helperText={formik.touched.room && formik.errors.room}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>
                    </>
                )}
                {[
                  { label: "Name", name: "name", type: "text" },
                  { label: "Email", name: "email", type: "text" },
                  { label: "Phone", name: "phone", type: "text" },
                ].map(({ label, name, type }) => (
                  <Grid item xs={4} key={name}>
                    <MDBox mb={2}>
                      <TextField
                        type={type}
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{ shrink: true }}
                        error={formik.touched[name] && Boolean(formik.errors[name])}
                        helperText={formik.touched[name] && formik.errors[name]}
                      />
                    </MDBox>
                  </Grid>
                ))}

                <Grid item xs={4}>
                  <MDBox mb={2}>
                    <TextField
                      label="Arrival Date"
                      type="date"
                      fullWidth
                      value={formik.values.arrived_date || ""}
                      onChange={(e) => handleDateChange("arrived_date", e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={formik.touched.arrived_date && Boolean(formik.errors.arrived_date)}
                      helperText={formik.touched.arrived_date && formik.errors.arrived_date}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={4}>
                  <MDBox mb={2}>
                    <TextField
                      label="Departure Date"
                      type="date"
                      fullWidth
                      value={formik.values.departure_date || ""}
                      onChange={(e) => handleDateChange("departure_date", e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={formik.touched.departure_date && Boolean(formik.errors.departure_date)}
                      helperText={formik.touched.departure_date && formik.errors.departure_date}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={4}>
                  <MDBox mb={2}>
                    <TextField
                      label="Total Stay (Days)"
                      fullWidth
                      value={formik.values.total_stay || ""}
                      disabled
                      variant="outlined"
                    />
                  </MDBox>
                </Grid>

                {[
                  { label: "Check-In Time", name: "check_in_time" },
                  { label: "Check-Out Time", name: "check_out_time" },
                  { label: "Guests", name: "guest", type: "number" },
                  { label: "Notes", name: "notes", type: "text" },
                  { label: "Rent amount", name: "rent_amount", type: "text" },
                  { label: "Deposit amount", name: "deposit_amount", type: "text" },
                  { label: "Booking Source", name: "booking_source", type: "select" },
                  { label: "Currency", name: "currency", type: "select" },
                  { label: "Payment method", name: "payment_method", type: "select" },
                ].map(({ label, name, type }) => {
                  if (name === "booking_source") {
                    return (
                      <Grid item xs={4} key={name}>
                        <MDBox mb={2}>
                          <FormControl fullWidth variant="outlined">
                            <Autocomplete
                              options={bookingSources || []}
                              onChange={(event, value) => {
                                if (value) {
                                  formik.setFieldValue(name, value);
                                }
                              }}
                              onInputChange={(event, inputValue) => {
                                formik.setFieldValue(name, inputValue);
                              }}
                              value={formik.values[name] || ""}
                              freeSolo
                              InputLabelProps={{ shrink: true }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={label}
                                  error={formik.touched[name] && Boolean(formik.errors[name])}
                                  helperText={formik.touched[name] && formik.errors[name]}
                                />
                              )}
                            />
                          </FormControl>
                        </MDBox>
                      </Grid>
                    );
                  }

                  if (name === "payment_method") {
                    return (
                      <Grid item xs={4} key={name}>
                        <MDBox mb={2}>
                          <FormControl fullWidth variant="outlined">
                            <Autocomplete
                              options={paymentSources || []}
                              onChange={(event, value) => {
                                if (value) {
                                  formik.setFieldValue(name, value);
                                }
                              }}
                              value={formik.values[name] || ""}
                              freeSolo
                              InputLabelProps={{ shrink: true }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={label}
                                  error={formik.touched[name] && Boolean(formik.errors[name])}
                                  helperText={formik.touched[name] && formik.errors[name]}
                                />
                              )}
                            />
                          </FormControl>
                        </MDBox>
                      </Grid>
                    );
                  }

                  if (name === "currency") {
                    return (
                      <Grid item xs={4} key={name}>
                        <MDBox mb={2}>
                          <FormControl fullWidth variant="outlined">
                            <Autocomplete
                              options={currencySources}
                              onChange={(event, value) => {
                                formik.setFieldValue(name, value);
                              }}
                              value={formik.values[name] || ""}
                              InputLabelProps={{ shrink: true }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={label}
                                  error={formik.touched[name] && Boolean(formik.errors[name])}
                                  helperText={formik.touched[name] && formik.errors[name]}
                                />
                              )}
                            />
                          </FormControl>
                        </MDBox>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={4} key={name}>
                      <MDBox mb={2}>
                        <TextField
                          type={type}
                          label={label}
                          fullWidth
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched[name] && Boolean(formik.errors[name])}
                          helperText={formik.touched[name] && formik.errors[name]}
                        />
                      </MDBox>
                    </Grid>
                  );
                })}
 
                {/* <Grid item xs={4}>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<UploadFileIcon style={{ color: "#7b809a" }} />}
                      sx={{ marginRight: "1rem" , "&:hover": {
                        borderColor: "#0d6efd",
                      }, }}
                    >
                      <span style={{ color: "#7b809a" }}>Upload receipt</span>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.png"
                        hidden
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setInvoiceFile(file);
                          formik.setFieldValue("payment_receipt", file); 
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Button>
                  </FormControl>
                </MDBox>
              </Grid> */}
              </Grid>
              <MDBox mt={4} mb={1}>
                <MDButton
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={!isFormFilled}
                >
                  Submit
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <Button sx={{ color: "#0d6efd" }} fullWidth onClick={CloseModal}>
                  Close
                </Button>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </Modal>
      <ToastContainer limit={1} />
    </>
  );
};

export default ReservationModel;
