import * as React from "react"; 
import Modal from "@mui/material/Modal"; 
import { useFormik } from "formik"; 
import * as Yup from "yup";   
import MDBox from "components/MDBox"; 
import MDTypography from "components/MDTypography"; 
import MDButton from "components/MDButton"; 
import { Button, Card, Grid, TextField, FormControl, Autocomplete, Typography } from "@mui/material"; 
import { ToastContainer, toast } from "react-toastify"; 
import axios from "axios"; 
import { useEffect, useState } from "react"; 
import UploadFileIcon from '@mui/icons-material/UploadFile';  

const style = {   
  position: "absolute",   
  top: { xs: 400, sm: 400, md: 400, xl: 400 },   
  width: 600,   
  left: "50%",   
  transform: "translate(-50%, -50%)",   
  bgcolor: "background.paper",   
  boxShadow: 24,   
  pt: 2,   
  pb: 3, 
};

const ExpensesModel = ({ isVisible, Close, fetchExpenses }) => {   
  const [file, setFile] = useState(null);   
  const [fileUrl, setFileUrl] = useState(null);   
  const [fileName, setFileName] = useState("");   
  const [isFileValid, setIsFileValid] = useState(true);
  const [properties, setProperties] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  const [IsSelectProperty, setSelectProperty]=useState(null);
  const [IsSelectRoom,setSelectRoom]=useState(null);
  const validationSchema = Yup.object({
    property: Yup.object()
    .shape({
      property_id: Yup.mixed().required("Property is required"),
      property_name: Yup.mixed().required("Property name is required"),
    })
    .nullable()
    .required("Property is required"),
  room: Yup.object()
    .shape({
      room_id: Yup.string().required("Room is required"),
      room_no: Yup.string().required("Room number is required"),
    })
    .nullable()
    .required("Room is required"),
  });
  const onSubmit = async (values) => {
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("date", values.date || "");
      formData.append("description", values.description || "");
      formData.append("property_id", values.property_id || "");
      formData.append("room_id", values.room_id || "");
      if (file) {
        formData.append("invoice", file);
      }
      const token = localStorage.getItem("token");         
      if (!token) {           
        toast.error("User is not authenticated");           
        return;         
      }         
               
             
      const response = await axios.post(           
        `${process.env.REACT_APP_API_PATH}/addexpense/property_id/${IsSelectProperty}/room_id/${IsSelectRoom} `,           
        formData,           
        {             
          headers: {               
            Authorization: `Bearer ${token}`,             
          },           
        }         
      );          
      if (response && response.status === 200) {           
        toast.success("Expenses added successfully!");           
        formik.resetForm();           
        CloseModal();           
        fetchExpenses(formData)         
      }   
    } catch (error) {
      console.error("Failed to add/update room data. Please try again.", error);
    }
  };
  const formik = useFormik({     
    initialValues: {       
      title: "",       
      date: "",       
      description: "",       
      property: null,  
      room: null, 
    },  
    validationSchema,       
    onSubmit: async (values) => {       
      onSubmit(values);
      formik.resetForm();    
    },   
  });    

  const handleFileChange = (e) => {     
    const selectedFile = e.target.files[0];     
    if (selectedFile) {       
      const fileType = selectedFile.type.split("/")[1];       
      if (!['pdf', 'jpg', 'jpeg', 'png'].includes(fileType)) {         
        setIsFileValid(false);
        setFile(null);         
        setFileUrl(null);         
        setFileName("");       
        return;       
      } else {         
        setIsFileValid(true);       
        const url = URL.createObjectURL(selectedFile);         
        setFileUrl(url);         
        setFile(selectedFile);         
        setFileName(selectedFile.name);       
      }     
    }   
  };

  const openFileInNewTab = () => {     
    if (fileUrl) {       
      window.open(fileUrl, "_blank");     
    }   
  };

  const CloseModal = () => {     
    formik.resetForm();     
    Close();   
  };

  const addProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };
  const fetchRoomsData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getRoomData`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setRoomsData(response.data.data);
      } else {
        console.error("Failed to fetch room data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching room data:", error);
    }
  };
  useEffect(() => {
    fetchRoomsData();
    addProperty();
  }, []);
  const filteredRooms = roomsData.filter((room) => room.property_id === IsSelectProperty);
  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );
  return (     
    <>     
      <Modal     
        open={isVisible}     
        aria-labelledby="parent-modal-title"     
        aria-describedby="parent-modal-description"     
      >     
        <form onSubmit={formik.handleSubmit}>         
          <Card           
            sx={{             
              ...style,             
              width: {               
                xs: "90%",               
                sm: "80%",               
                md: "500px",               
                lg: "60%",               
                xl: "600px",             
              },           
            }}         
          >           
            <MDBox             
              borderRadius="lg"             
              coloredShadow="info"             
              mx={2}             
              mt={-3}             
              p={2}             
              mb={1}             
              textAlign="center"             
              sx={{ backgroundColor: "#1e90ff" }}           
            >             
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>               
                Expenses Details             
              </MDTypography>           
            </MDBox>           
            <MDBox pt={4} pb={3} px={3}>             
              <Grid container spacing={2}>  
              <Grid item xs={6}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={properties}
                            getOptionLabel={(option) => option.property_name}
                            onChange={(event, value) => {
                              formik.setFieldValue("property", value);
                              setSelectProperty(value?.property_id);
                            }}
                            value={formik.values.property || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Property"
                                error={formik.touched.property && Boolean(formik.errors.property)}
                                helperText={formik.touched.property && formik.errors.property}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>

                    <Grid item xs={6}>
                      <MDBox mb={2}>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={filteredRooms}
                            getOptionLabel={(option) => option.room_no}
                            onChange={(event, value) => {
                              formik.setFieldValue("room", value);
                              setSelectRoom(value?.room_id);
                            }}
                            value={formik.values.room || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Room"
                                error={formik.touched.room && Boolean(formik.errors.room)}
                                helperText={formik.touched.room && formik.errors.room}
                              />
                            )}
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>             
                <Grid item xs={12} sm={6}>                 
                  <MDBox mb={2}>                   
                    <TextField                     
                      label="Expenses title"                     
                      type="text"                     
                      fullWidth                     
                      name="title"                     
                      value={formik.values.title}                     
                      onChange={formik.handleChange}                     
                      onBlur={formik.handleBlur}                     
                      InputLabelProps={{ shrink: true }}                     
                      multiline                     
                      rows={4}                     
                      error={formik.touched.title && Boolean(formik.errors.title)}                     
                      helperText={formik.touched.title && formik.errors.title}                   
                    />                 
                  </MDBox>               
                </Grid>               
                <Grid item xs={12} sm={6}>                 
                  <MDBox mb={2}>                   
                    <TextField                     
                      label="Expenses description"                     
                      type="text"                     
                      fullWidth                     
                      name="description"                     
                      value={formik.values.description}                     
                      onChange={formik.handleChange}                     
                      onBlur={formik.handleBlur}                     
                      InputLabelProps={{ shrink: true }}                     
                      multiline                     
                      rows={4}                     
                      error={formik.touched.description && Boolean(formik.errors.description)}                     
                      helperText={formik.touched.description && formik.errors.description}                   
                    />                 
                  </MDBox>               
                </Grid>               
                <Grid item xs={12} sm={6}>                 
                  <MDBox mb={2}>                   
                    <TextField                     
                      label="Date"                     
                      type="date"                     
                      fullWidth                     
                      name="date"                     
                      value={formik.values.date}                     
                      onChange={formik.handleChange}                     
                      onBlur={formik.handleBlur}                     
                      InputLabelProps={{ shrink: true }}                     
                      error={formik.touched.date && Boolean(formik.errors.date)}                     
                      helperText={formik.touched.date && formik.errors.date}                   
                    />                 
                  </MDBox>               
                </Grid>               
                <Grid item xs={12} sm={6}>                 
                  <MDBox >                   
                    <FormControl fullWidth>                     
                      <Button                       
                        component="label"                       
                        variant="outlined"                       
                        startIcon={<UploadFileIcon style={{ color: "#7b809a" }} />}                       
                        sx={{ marginRight: "1rem" , "&:hover": {                         
                          borderColor: "#0d6efd",                       
                        }, }}                     
                      >                       
                        <span style={{ color: "#7b809a" }}>Upload Invoice</span>                       
                        <input                         
                          type="file"                         
                          accept=".pdf,.doc,.docx,.jpg,.png"                         
                          hidden                         
                          onChange={handleFileChange}                       
                        />                     
                      </Button>                   
                    </FormControl>                 
                  </MDBox>                 
                  <MDTypography variant="caption" onClick={openFileInNewTab} sx={{ marginTop: 1, cursor: "pointer", fontSize: "14px", color: "#007bff" }}>
                    {fileName || fileUrl ? fileName || fileUrl : "No file selected"}                 
                  </MDTypography>                 
                  {!isFileValid && (                     
                    <Typography variant="caption" color="error">                       
                      Invalid file format! Only .pdf, .jpg, .png files are allowed.                     
                    </Typography>                   
                  )}               
                </Grid>               
                {/* <Grid item xs={6}>                 
                  <MDBox mb={2}>                   
                    <FormControl fullWidth variant="outlined">                     
                      <Autocomplete                       
                        options={rooms}                       
                        getOptionLabel={(option) => option?.property_name && option?.room_no ? `${option.property_name} - Room ${option.room_no}` : ""}                       
                        onChange={(event, value) => {                         
                          formik.setFieldValue("reserveroom_id", value);                         
                          handleReservationChange(value);                       
                        }}                       
                        value={formik.values.room || ""}                       
                        renderInput={(params) => (                         
                          <TextField                           
                            {...params}                           
                            label="Reservation"                           
                            error={formik.touched.room && Boolean(formik.errors.room)}                           
                            helperText={formik.touched.room && formik.errors.room}                         
                          />                       
                        )}                     
                      />                   
                    </FormControl>                 
                  </MDBox>               
                </Grid>              */}
              </Grid>              
              <MDBox mt={4} mb={1}>               
                <MDButton                 
                  sx={{ backgroundColor: "#0d6efd" }}                 
                  color="info"                 
                  fullWidth                 
                  type="submit"                 
                  disabled={!isFormFilled}               
                >                 
                  Submit               
                </MDButton>             
              </MDBox>              
              <MDBox mt={3} mb={1} textAlign="center">               
                <Button sx={{ color: "#0d6efd" }} fullWidth onClick={CloseModal}>                 
                  Close               
                </Button>             
              </MDBox>           
            </MDBox>         
          </Card>       
        </form>     
      </Modal>     
      <ToastContainer limit={1}/>     
    </>   
  ); 
};

export default ExpensesModel;
