import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid, Autocomplete, TextField, FormControl, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AttachFileIcon from '@mui/icons-material/AttachFile';
const style = {
  position: "absolute",
  top: { xs: 340, sm: 340, md: 340, xl: 420 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const roomTypes = ["2BHK", "3BHK"];
const bedOptions = ["1", "2", "3", "4"];

const AddRoom = ({ isVisible, Close, onAddRoom, selectedRoom }) => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(""); 
  const [isPdfFile,setIsPdfFile]=useState("");
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
     const name = url.split("/")[3]
     setIsPdfFile(name);
      setFileUrl(url  || ""); 
      setFile(selectedFile);
    }
  };

  const openFileInNewTab = () => {
    if (fileName || fileUrl) {
      window.open(fileName || fileUrl, "_blank");
    }
  };

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();

      formData.append("room_no", values.room_no);
      formData.append("room_type", values.room_type || "");
      formData.append("room_size_sqm", values.room_size_sqm || "");
      formData.append("room_size_jou", values.room_size_jou || "");
      formData.append("bed", values.bed || "");
      formData.append("rent_history", values.rent_history || "");
      formData.append("sort_term_daily_rent", values.sort_term_daily_rent || "");
      formData.append("utility_history", values.utility_history || "");

      if (file) {
        formData.append("pdf_file", file);
      }
      let response;

      if (selectedRoom) {
        response = await axios.put(
          `${process.env.REACT_APP_API_PATH}/updateRoomData/${id}/room/${selectedRoom.room_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/roomdata/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      if (response.status === 200) {
        toast.success(
          selectedRoom ? "Room data updated successfully!" : "Room added successfully!"
        );
        onAddRoom(response.data.data);
        formik.resetForm();
        Close();
      }
    } catch (error) {
      console.error("Failed to add/update room data. Please try again.", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      room_no: "",
      room_type: "",
      room_size_sqm: "",
      room_size_jou: "",
      bed: "",
      rent_history: "",
      sort_term_daily_rent: "",
      utility_history: "",
    },
    onSubmit: async (values) => {
      onSubmit(values);
    },
    validate: (values) => {
      const errors = {};
      if (!values.room_no) {
        errors.room_no = "Room Number is required";
      } 

      return errors;
    },
  });

  useEffect(() => {
    if (selectedRoom) {
      formik.setValues({
        room_no: selectedRoom.room_no || "",
        room_type: selectedRoom.room_type || "",
        room_size_sqm: selectedRoom.room_size_sqm || "",
        room_size_jou: selectedRoom.room_size_jou || "",
        bed: selectedRoom.bed || "",
        rent_history: selectedRoom.rent_history || "",
        sort_term_daily_rent: selectedRoom.sort_term_daily_rent || "",
        utility_history: selectedRoom.utility_history || "",
        pdf_file:selectedRoom.pdf_file || "",
      });
      setFileName(selectedRoom.pdf_file || ""); 
    } else {
      formik.resetForm();
    }
  }, [selectedRoom]);
  const closeModal = () => {
    formik.resetForm();
    Close();
  };
const splitFile = fileName.split('/uploads/').pop() 
  const isFormFilled = Object.values(formik.values).some(value => value.trim() !== "");

  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "600px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {selectedRoom ? "Edit Room" : "Add Room"}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {[
                  { label: "Room Number", name: "room_no", type: "text" },
                  { label: "Type", name: "room_type", options: roomTypes },
                  { label: "Size (sqm)", name: "room_size_sqm", type: "text" },
                  { label: "Size (jou)", name: "room_size_jou", type: "text" },
                  { label: "Bed", name: "bed", options: bedOptions },
                  { label: "Rent History", name: "rent_history", type: "text" },
                  { label: "Short Term Daily Rent", name: "sort_term_daily_rent", type: "text" },
                  { label: "Utilities History", name: "utility_history", type: "text" },
                ].map(({ label, name, options, type = "text" }) => (
                  <Grid item xs={6} key={name}>
                    <MDBox mb={2}>
                      {options ? (
                        <Autocomplete
                          disablePortal
                          options={options}
                          value={formik.values[name]}
                          onChange={(event, value) => {
                            formik.setFieldValue(name, value);
                          }}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={label}
                              error={formik.touched[name] && Boolean(formik.errors[name])}
                            />
                          )}
                        />
                      ) : (
                        <MDInput
                          type={type}
                          label={label}
                          fullWidth
                          name={name}
                          value={formik.values[name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        
                      )}
                       <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched[name] && formik.errors[name] && (
                        <div>{formik.errors[name]}</div>
                      )}
                    </div>
                    </MDBox>
                  </Grid>
                ))}
                <Grid item xs={6}>
                  <MDBox display="flex" alignItems="center" mb={2}>
                    <FormControl fullWidth>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<AttachFileIcon style={{ color: "#7b809a" }} />}
                      sx={{ marginRight: "1rem" , "&:hover": {
                        borderColor: "#0d6efd",
                      }, }}
                    >
                      <span style={{ color: "#7b809a" }}>Upload PDF</span>
                      <input
                        type="file"
                        accept="application/pdf,.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                    <MDTypography variant="caption" onClick={openFileInNewTab} sx={{    marginTop: 1,
                    cursor: "pointer",
                    fontSize: "14px",  
                    color: "#007bff", }}>
                    {splitFile || fileUrl && (
                  <Typography variant="caption"  >
      {splitFile || isPdfFile}
      </Typography>              
   
  )}
  </MDTypography>
                  </FormControl>
                  
                  </MDBox>
     
  
                </Grid>
                
              </Grid>
              <MDBox mt={4} mb={1}>
                <MDButton sx={{ backgroundColor: "#0d6efd" }} color="info" fullWidth type="submit" disabled={!isFormFilled}>
                  Submit
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <Button sx={{ color: "#0d6efd" }} fullWidth onClick={closeModal}>
                  Close
                </Button>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </Modal>
      <ToastContainer limit={1} />
    </>
  );
};

export default AddRoom;
