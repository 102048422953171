import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
const PrivateRoutes = () => {
  const isAllowed = localStorage.getItem("token"); 
  // const isAllowed = Cookies.get("token");
  return isAllowed ? (
    <Outlet /> 
  ) : (
    <Navigate to="/" /> 
  );
};

export default PrivateRoutes;
