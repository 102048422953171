import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Button, Card, Grid,  } from "@mui/material";
import axios from "axios"; 
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify"; 

const style = {
  position: "absolute",
  top:  { xs: 300, sm: 300, md: 300 , xl: 350},
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
 
};

export default function ModelData(props) {
  const { isVisible, addProperty, close, selectedProperty } = props;


  const formik = useFormik({
    initialValues: {
      property_name: "",
      city: "",
      zip: "",
      ward: "",
      location: "",
      street: "",
    },
    onSubmit: async (values) => {
      try {
        let response;

        if (selectedProperty) {
          response = await axios.put(
            `${process.env.REACT_APP_API_PATH}/updateProperties/${selectedProperty.property_id}`, 
            values 
          );
        } else {
          response = await axios.post(`${process.env.REACT_APP_API_PATH}/addProperties`, values);
        }

        if (response.status === 200) {
          toast.success(selectedProperty ? "Property data updated successfully!" : "Property added successfully!");
          addProperty(values);
          formik.resetForm(); 
          close(); 
        }
      } catch (error) {
        console.error("Error saving property:", error);
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.property_name) errors.property_name = "Property Name is required";
      return errors;
    },
  });

  useEffect(() => {
    if (selectedProperty) {
      formik.setValues({
        property_name: selectedProperty.property_name|| "",
        city: selectedProperty.city|| "",
        zip: selectedProperty.zip|| "",
        ward: selectedProperty.ward|| "",
        location: selectedProperty.location|| "",
        street: selectedProperty.street|| "",
      });
    }
  }, [selectedProperty]);

  const closeModal = () => {
    formik.resetForm();
    close();
  };
  const isFormFilled = Object.values(formik.values).some(value => value.trim() !== "");
  return (
    <>
    <Modal
      open={isVisible}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <Card  sx={{
            ...style,
            width: {
              xs: '90%', 
              sm: '80%', 
              md: '500px', 
              lg: '60%', 
              xl: '600px',
            },
          }}>
          <MDBox
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
            sx={{ backgroundColor: "#1e90ff" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {selectedProperty ? "Edit Property" : "Create Property"}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
          
            <Grid container spacing={2}>
                {[
                  { label: "Property Name", name: "property_name" },
                  { label: "City", name: "city" },
                  { label: "Zip Code", name: "zip" },
                  { label: "Ward", name: "ward" },
                  { label: "Location", name: "location" },
                  { label: "Street", name: "street" },
                ].map(({ label, name }) => (
                  <Grid item xs={6} key={name}>
                    <MDBox mb={2}>
                      <MDInput
                        label={label}
                        fullWidth
                        name={name}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched[name] && formik.errors[name] && (
                        <div>{formik.errors[name]}</div>
                      )}
                    </div>
                    </MDBox>
                  </Grid>
                ))}
            
              </Grid>
            <MDBox mt={4} mb={1}>
              <MDButton sx={{ backgroundColor: "#0d6efd" }} color="info" fullWidth type="submit" disabled={!isFormFilled}>
                Submit
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <Button sx={{ color: "#0d6efd" }} fullWidth onClick={closeModal}>
                Close
              </Button>
            </MDBox>
          </MDBox>
        </Card>
      </form>
    </Modal>
     <ToastContainer limit={1}/>
     </>
  );
}
